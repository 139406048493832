<template>
  <div class="card-exam-page">
    <div class="card-exam-header">
      <div class="columns">
        <div class="column col-3 col-md-12 col-sm-12">
          <div class="text-bold mt-1">Guia de exames</div>
        </div>
        <div class="column hide-md hide-sm" />
        <div class="column col-auto col-md-12 col-sm-12">
          <div class="input-group" :class="{loading: loadingModels}">
            <select class="form-select" v-model="modelId"
                    :class="{loading: loadingModels}"
                    :disabled="loadingModels"
                    @change="getModel">
              <option value="">Meus protocolos de exames</option>
              <option value="mostRequested">Mais solicitados</option>
              <option v-for="(item, i) in models" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
            <button class="btn btn-neutral input-group-btn"
                    @click="openEditModel">
              Gerenciar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-exam-body">
      <div class="columns">
        <div class="column col-3 col-md-12 col-sm-12 form-group"
             :class="{'has-error': $v.form.date.$error}">
          <label class="form-label">Data solicitação</label>
          <div class="input-group">
            <dx-input-date class="text-center"
                           @focus="setDate"
                           v-model="form.date"
                           @blur="$v.form.date.$touch()" />
            <button class="btn btn-neutral input-group-btn btn-icon"
                    @click="form.date = ''" tabindex="-1">
              <fa-icon :icon="['fal', 'times']"/>
            </button>
          </div>
          <template v-if="$v.form.date.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.date.date">Data inválida</div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.date.minDate">A data não pode ser inferior a hoje</div>
          </template>
        </div>
        <div class="column col-4 col-md-12 col-sm-12 form-group">
          <label class="form-label">Convênio</label>
          <select id="insurance" class="form-select"
                  v-model="form.insuranceId"
                  @change="changeInsurance">
            <option value="">Selecione</option>
            <option v-for="item in insurances" :key="item.id"
                    :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="column col-5 col-md-12 col-sm-12 form-group">
          <label class="form-label">Profissional solicitante</label>
          <div class="input-group">
            <select id="insurance" class="form-select"
                    v-model="form.professional.id"
                    v-if="requesterProfessionalEdit">
              <option value="">Selecione</option>
              <option v-for="item in professionals" :key="item.id"
                      :value="item.id">{{ item.name }}</option>
            </select>
            <input type="text" class="form-input"
                   v-if="!requesterProfessionalEdit"
                   v-model="form.professional.name" readonly>
            <button class="btn btn-primary input-group-btn btn-icon"
                    @click="requesterSave"
                    v-if="requesterProfessionalEdit"
                    tabindex="-1">
              <fa-icon :icon="['fal', 'save']"/>
            </button>
            <button class="btn btn-neutral input-group-btn btn-icon"
                    @click="requesterEdit"
                    v-if="!requesterProfessionalEdit"
                    tabindex="-1">
              <fa-icon :icon="['fal', 'pencil']"/>
            </button>
            <button class="btn btn-neutral input-group-btn btn-icon"
                    tabindex="-1" @click="requesterRemove">
              <fa-icon :icon="['fal', 'times']"/>
            </button>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column col-3 col-md-12 col-sm-12 form-group">
          <label class="form-label">Caráter do atendimento</label>
          <select id="character-attendance"
                  class="form-select" v-model="form.character">
            <option value="elective">Eletivo</option>
            <option value="emergency">Urgência/Emergência</option>
          </select>
        </div>
        <div class="column col-2 col-md-12 col-sm-12 form-group">
          <label class="form-label">Atendimento RN</label>
          <select id="isNewborn"
                  class="form-select" v-model="form.isNewborn">
            <option value="false">Não</option>
            <option value="true">Sim</option>
          </select>
        </div>
        <div class="column col-2 col-md-12 col-sm-12 form-group">
          <label for="referral" class="form-label">Guia prestador</label>
          <input type="text" id="referral" name="referral" class="form-input"
                 v-model="form.referral" autocomplete="nope"
                 placeholder="00000000" v-mask-number>
        </div>
        <div class="column col-5 col-md-12 col-sm-12" style="align-self: flex-end">
          <label class="form-switch mb-2">
            <input type="checkbox" v-model="isSPSADT">
            <i class="form-icon"></i> Imprimir em guia SP/SADT
          </label>
        </div>
      </div>
      <div class="columns form-group">
        <div class="column col-6 col-md-12 col-sm-12 form-group">
          <label class="form-label">Indicação clínica</label>
          <input type="text" name="clinical-indication"
                 class="form-input" v-model="form.clinicalIndication"
                 autocomplete="nope">
        </div>
        <div class="column col-6 col-md-12 col-sm-12 form-group">
          <label  class="form-label">Observação / Justificativa</label>
          <input type="text" class="form-input" v-model="form.justification"
                 autocomplete="nope">
        </div>
      </div>
      <div class="empty mb-2" v-if="form.expenses.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Lista de exames</p>
        <p class="empty-subtitle">
          Nenhum exame adicionado. Utilize o campo abaixo para realizar a busca.
        </p>
      </div>
      <table class="table" v-else>
        <thead>
        <tr>
          <th width="50px">#</th>
          <th>Código</th>
          <th>Descrição</th>
          <th class="text-center" width="20px">Quantidade</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in form.expenses" :key="i">
          <td>{{ i + 1 }}</td>
          <td width="100px">{{ item.code | tuss }}</td>
          <td>
            <input type="text" class="form-input input-sm"
                   v-model="item.name">
          </td>
          <td class="text-center">
            <input type="text" class="form-input input-sm text-center"
                   v-mask-number maxlength="2"
                   v-model="item.quantity">
          </td>
          <td class="text-right" width="40px">
            <button class="btn btn-sm btn-action btn-icon btn-error"
                    @click="removeExpense(i)">
              <fa-icon :icon="['fal', 'times']"></fa-icon>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="columns find-expense">
        <div class="column col-8 col-md-12 col-sm-12">
          <dx-autocomplete
            id="expense"
            ref="expense"
            v-model="expense"
            :source="findExpense"
            label="expense"
            track-by="expense-id"
            @select="setExpense"
            :debounce="800"
            placeholder="Pesquisar exame por código ou descrição">
            <button slot="action"
                    class="btn btn-neutral btn-action input-group-btn btn-icon">
              <fa-icon :icon="['fal', 'search']"/>
            </button>
            <template v-slot="{ item }">
              <span>
                <fa-icon class="text-primary" :icon="['fal', 'file-medical-alt']"></fa-icon>
                {{ item.name }} ({{ item.code | tuss }})
              </span>
              <span>{{ item.display }}</span>
            </template>
          </dx-autocomplete>
        </div>
      </div>
    </div>
    <div class="card-exam-footer">
      <button class="btn btn-gray btn-icon-left"
              @click="print" :class="{loading: printing}"
              :disabled="!haveExpenses || printing">
        <fa-icon :icon="['fal', 'print']"/>
        Imprimir
      </button>
      <button class="btn btn-icon-left btn-error ml-2"
              @click="remove">
        <fa-icon :icon="['fal', 'trash']"/>
        Excluir
      </button>
      <div class="popover ml-2">
        <div class="popover-container">
          <div class="card">
            <div class="card-header">
              <h6>Protocolo de exames</h6>
            </div>
            <div class="card-body">
              Você pode salvar diversos protocolos de exames
              para ser utilizado em outros pacientes
            </div>
          </div>
        </div>
<!--        <button class="btn btn-icon-left btn-neutral"-->
<!--                :class="{loading: savingModel}"-->
<!--                @click="saveModel" :disabled="!haveExpenses || savingModel">-->
<!--          <fa-icon :icon="['fal', 'book-medical']"/>-->
<!--          <span v-if="modelId">Atualizar meu protocolo</span>-->
<!--          <span v-else>Adicionar em meus modelos de protocolos</span>-->
<!--        </button>-->
      </div>
    </div>
    <dx-modal title="Exames mais solicitados"
              :value="modalMostRequested.show" size="lg"
              @input="modalMostRequested.show = false" id="modal-most-requested">
      <template v-if="modalMostRequested.items.length === 0">
        <div class="empty mt-2">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Nenhum exame solicitado</p>
          <p class="empty-subtitle">
            Ainda não foi realizado nenhuma solicitação de exames.
          </p>
        </div>
      </template>
      <table class="table table-striped table-hover" v-else>
        <thead>
        <tr>
          <th></th>
          <th>Código</th>
          <th>Descrição</th>
          <th class="text-center">Quantidade</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in modalMostRequested.items" :key="i">
          <td class="text-center" width="60px">
            <div class="form-group">
              <label class="form-checkbox">
                <input type="checkbox" v-model="item.selected">
                <i class="form-icon"></i>
              </label>
            </div>
          </td>
          <td>{{ item.code | tuss }}</td>
          <td>{{ item.name }}</td>
          <td class="text-center">{{ item.quantity }}</td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn btn-gray mr-1"
                :class="{loading: modalMostRequested.saving}"
                :disabled="modalMostRequested.saving
                  || modalMostRequested.items.length === 0"
                @click="addMostRequested">Adicionar</button>
        <button class="btn" @click="modalMostRequested.show = false">Sair</button>
      </template>
    </dx-modal>
    <dx-modal title="Protocolo selecionado"
              :value="modalSelectedProtocol.show" size="lg"
              @input="modalSelectedProtocol.show = false" id="modal-selected-model">
      <template v-if="modalSelectedProtocol.items.length === 0">
        <div class="empty mt-2">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Nenhum exame</p>
          <p class="empty-subtitle">
            Nenhum exame encontrado neste modelo.
          </p>
        </div>
      </template>
      <table class="table table-striped table-hover" v-else>
        <thead>
        <tr>
          <th>
            <label class="form-checkbox text-primary">
              <input type="checkbox"
                     @change="selectAll"
                     :checked="getProtocolSelected"
                     :indeterminate.prop="getProtocolSelected === null">
              <i class="form-icon"/>
            </label>
          </th>
          <th>Código</th>
          <th>Descrição</th>
          <th class="text-center">Quantidade</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in modalSelectedProtocol.items" :key="i">
          <td class="text-center" width="60px">
            <div class="form-group">
              <label class="form-checkbox">
                <input type="checkbox" v-model="item.selected">
                <i class="form-icon"></i>
              </label>
            </div>
          </td>
          <td>{{ item.code | tuss }}</td>
          <td>{{ item.name }}</td>
          <td class="text-center">{{ item.quantity }}</td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn btn-gray mr-1"
                :class="{loading: modalSelectedProtocol.saving}"
                :disabled="modalSelectedProtocol.saving
                  || !hasSelectedProtocol"
                @click="addSelectedProtocol">Adicionar</button>
        <button class="btn" @click="modalSelectedProtocol.show = false">Sair</button>
      </template>
    </dx-modal>
    <modal-edit
      v-if="editModels.show"
      :show="editModels.show"
      @close="closeEditModel"
    />
    <dx-modal title="Encaminhar exame(s)" size="sm"
              v-model="modalExamAppointment.show"
              v-if="modalExamAppointment.show"
              id="modal-exam-schedule">
      <div class="columns">
        <div class="column col-12 form-group">
          <div class="date-item">
            <button class="btn btn-icon btn-gray"
                    :disabled="!isValidDate"
                    @click="removeDate">
              <fa-icon :icon="['fal', 'chevron-left']"/>
            </button>
            <div>
              <small class="text-gray">
                {{ modalExamAppointment.form.date | date('dddd') }}
              </small>
              <div class="text-bold">
                {{ modalExamAppointment.form.date | date }}
              </div>
            </div>
            <button class="btn btn-icon btn-gray" @click="addDate">
              <fa-icon :icon="['fal', 'chevron-right']"/>
            </button>
          </div>
        </div>
        <div class="column col-12 form-group"
             :class="{'has-error': $v.modalExamAppointment.form.scheduleId.$error}">
          <label class="form-label">Agenda</label>
          <select id="schedule-name" class="form-select"
                  @blur="$v.modalExamAppointment.form.scheduleId.$touch()"
                  @change="loadScheduleProfessionals"
                  v-model="modalExamAppointment.form.scheduleId">
            <option value="">[Selecione]</option>
            <option v-for="(item, i) in modalExamAppointment.schedules"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
          <template v-if="$v.modalExamAppointment.form.scheduleId.$error">
            <div class="form-input-hint"
                 v-if="!$v.modalExamAppointment.form.scheduleId.required">
              Campo Obrigatório
            </div>
          </template>
        </div>
        <div class="column col-12 form-group"
             :class="{'has-error': $v.modalExamAppointment.form.professionalId.$error}">
          <label class="form-label">Técnico</label>
          <select id="professional-name" class="form-select"
                  @blur="$v.modalExamAppointment.form.professionalId.$touch()"
                  v-model="modalExamAppointment.form.professionalId">
            <option value="">[Selecione]</option>
            <option v-for="(item, i) in modalExamAppointment.professionals"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
          <template v-if="$v.modalExamAppointment.form.professionalId.$error">
            <div class="form-input-hint"
                 v-if="!$v.modalExamAppointment.form.professionalId.required">
              Campo Obrigatório
            </div>
          </template>
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-gray btn-icon btn-icon-left mr-1"
                :class="{loading: modalExamAppointment.saving}"
                :disabled="modalExamAppointment.saving"
                @click="saveExamAppointment">
          <fa-icon :icon="['fal', 'save']" />
          Agendar
        </button>
        <button class="btn" @click="modalExamAppointment.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { randomHash } from '@/helpers';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '../../../../../data/validators';
import ModalEdit from '../../../../template/components/request/modals/Main.vue';
import { clone } from '../../../../../helpers/object';

export default {
  components: {
    ModalEdit,
  },
  props: {
    medicalRecordId: {
      type: String,
    },
    lastRequests: {
      type: Array,
    },
    patient: {
      id: {
        type: String,
      },
      name: {
        type: String,
      },
      gender: {
        type: String,
      },
      birthDate: {
        type: Date,
      },
      phone: {
        type: String,
      },
      cellphone: {
        type: String,
      },
      email: {
        type: String,
      },
      insuranceId: {
        type: String,
      },
      record: {
        type: String,
      },
      validity: {
        type: String,
      },
    },
    data: {
      id: {
        type: String,
      },
      requestType: {
        type: String,
      },
      date: {
        type: Date,
      },
      insuranceId: {
        type: String,
      },
      appointmentId: {
        type: String,
      },
      professional: {
        id: {
          type: String,
        },
        name: {
          type: String,
        },
      },
      referral: {
        type: String,
      },
      character: {
        type: String,
      },
      isNewborn: {
        type: Boolean,
      },
      clinicalIndication: {
        type: String,
      },
      expenses: {
        type: Array,
      },
    },
  },
  data() {
    return {
      insurances: [],
      professionals: [],
      models: [],
      modelId: '',
      debounceTimeout: null,
      expense: '',
      loadingModels: false,
      printing: false,
      savingModel: false,
      isSPSADT: true,
      modalExamAppointment: {
        loading: false,
        saving: false,
        show: false,
        schedules: [],
        professionals: [],
        form: {
          date: '',
          scheduleId: '',
          professionalId: '',
        },
      },
      modalMostRequested: {
        show: false,
        saving: false,
        items: [],
      },
      modalSelectedProtocol: {
        show: false,
        name: '',
        saving: false,
        items: [],
      },
      editModels: {
        show: false,
      },
      requesterProfessionalEdit: false,
      form: {
        id: '',
        type: '',
        appointmentId: '',
        professional: {
          id: '',
          name: '',
        },
        date: '',
        insuranceId: '',
        referral: '',
        character: '',
        isNewborn: false,
        clinicalIndication: '',
        justification: '',
        expenses: [],
      },
    };
  },
  validations() {
    const rules = {
      form: {
        date: { date, minDate: minDate() },
      },
      modalExamAppointment: {
        form: {
          scheduleId: {},
          professionalId: {},
        },
      },
    };

    if (this.modalExamAppointment.show) {
      rules.modalExamAppointment.form.scheduleId = { required };
      rules.modalExamAppointment.form.professionalId = { required };
    }

    return rules;
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    haveExpenses() {
      return this.form.expenses.length > 0;
    },
    hasSelectedProtocol() {
      return this.modalSelectedProtocol.items.some(item => item.selected);
    },
    isValidDate() {
      const now = moment().format('YYYY-MM-DD');
      return moment(this.modalExamAppointment.form.date).diff(now, 'days') > 0;
    },
    getProtocolSelected() {
      if (!this.modalSelectedProtocol.items || this.modalSelectedProtocol.items.length === 0) {
        return false;
      }

      const selected = this.modalSelectedProtocol.items.filter(item => item.selected);
      if (selected.length === 0) {
        return false;
      }
      if (selected.length === this.modalSelectedProtocol.items.length) {
        return true;
      }
      return null;
    },
  },
  async mounted() {
    this.loadMostRequested();
    this.loadModels();
    await this.loadInsurances();
    this.form = this.data;
    if (this.form.date === null) {
      this.form.date = '';
    }
    if (this.form.professional === null) {
      this.form.professional = {
        id: '',
        name: 'Não informar',
      };
    }

    const foundInsurance = this.insurances.find(({ id }) => id === this.form.insuranceId);
    if (!foundInsurance) this.form.insuranceId = '';
    this.changeInsurance();
    this.setWatchers();
  },
  methods: {
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    setDate() {
      if (!this.form.date) {
        this.form.date = moment().format('YYYY-MM-DD');
      }
    },
    changeInsurance() {
      if (!this.form.insuranceId) {
        return;
      }
      const insurance = this.insurances
        .find(({ id }) => id === this.form.insuranceId);

      if (insurance) {
        this.isSPSADT = insurance.type === 'insurance';
      }
    },
    setWatchers() {
      this.$watch('form', {
        handler() {
          clearTimeout(this.debounceTimeout);
          this.debounceTimeout = setTimeout(this.save, 1000);
        },
        deep: true,
      });
    },
    loadInsurances() {
      const params = {
        limit: 0,
      };
      return this.$http.get('/insurances', { params })
        .then(({ data }) => {
          this.insurances = data.items;
        })
        .catch(this.$toast.error);
    },
    loadProfessionals() {
      return this.apiFetchProfessionals()
        .then((items) => {
          this.professionals = items;
        })
        .catch(this.$toast.error);
    },
    loadModels() {
      const params = {
        active: true,
        type: 'exam',
        professionalId: this.user.id,
        limit: 0,
      };
      this.$http.get('/request-templates', { params })
        .then(({ data }) => {
          this.models = data.items;
        })
        .catch(this.$toast.error);
    },
    selectAll(e) {
      this.modalSelectedProtocol.items
        .forEach((item) => {
          item.selected = e.target.checked;
        });
    },
    getModel() {
      if (!this.modelId) {
        this.modalSelectedProtocol.name = '';
        return;
      }

      if (this.modelId === 'mostRequested') {
        this.modalMostRequested.items.forEach((item) => {
          item.selected = false;
        });
        this.modalMostRequested.show = true;
      } else {
        this.loadingModels = true;
        this.$http.get(`/request-templates/${this.modelId}`)
          .then(({ data }) => {
            if (data && data.expenses.length > 0) {
              this.modalSelectedProtocol.name = data.name;
              this.modalSelectedProtocol.items = data.expenses.map(item => ({
                key: randomHash(),
                id: item.id,
                code: item.code,
                name: item.name,
                quantity: item.quantity,
                selected: true,
              }));
            }
          })
          .catch(this.$toast.error)
          .then(() => {
            this.modelId = '';
            this.modalSelectedProtocol.show = true;
            this.loadingModels = false;
          });
      }
    },
    openEditModel() {
      this.editModels.show = true;
    },
    closeEditModel() {
      this.loadModels();
      this.editModels.show = false;
    },
    addSelectedProtocol() {
      const selectedItems = this.modalSelectedProtocol.items
        .filter(({ selected }) => selected);
      if (selectedItems) {
        selectedItems.forEach((expense) => {
          const found = this.form.expenses.find(item => item.id === expense.id);
          if (found) {
            const result = Number(found.quantity);
            found.quantity = result + Number(expense.quantity);
          } else {
            this.form.expenses.push({
              key: randomHash(),
              id: expense.id,
              code: expense.code,
              name: expense.name,
              quantity: expense.quantity,
            });
          }
        });
      }
      this.modalSelectedProtocol.show = false;
    },
    addMostRequested() {
      const selectedItems = this.modalMostRequested.items
        .filter(({ selected }) => selected);
      if (selectedItems) {
        selectedItems.forEach((expense) => {
          const found = this.form.expenses.find(item => item.id === expense.id);
          if (found) {
            const result = Number(found.quantity);
            found.quantity = result + Number(expense.quantity);
          } else {
            this.form.expenses.push({
              key: randomHash(),
              id: expense.id,
              code: expense.code,
              name: expense.name,
              quantity: expense.quantity,
            });
          }
        });
      }
      this.modalMostRequested.show = false;
      this.modelId = '';
    },
    async saveExamAppointment() {
      this.$v.modalExamAppointment.form.$touch();
      if (this.$v.modalExamAppointment.form.$error) {
        return null;
      }

      this.modalExamAppointment.saving = true;

      const professional = this.modalExamAppointment.professionals
        .find(({ id }) => id === this.modalExamAppointment.form.professionalId);
      const specialty = professional.specialties.length > 0
        ? professional.specialties[0].code
        : null;

      const hour = moment().format('HH:mm');
      const AppointmentDate = `${
        this.modalExamAppointment.form.date}T${this.isValidDate ? '00:00' : hour
      }`;

      const data = {
        scheduleId: this.modalExamAppointment.form.scheduleId,
        startDate: AppointmentDate,
        endDate: AppointmentDate,
        slot: true,
        professional: {
          id: professional.id,
          specialty,
        },
        patient: {
          id: this.patient.id,
          name: this.patient.name,
          birthDate: this.patient.birthDate,
          gender: this.patient.gender,
          phone: this.patient.phone,
          cellphone: this.patient.cellphone,
          email: this.patient.email,
        },
        insurance: {
          planId: this.patient.insurancePlanId,
        },
        type: 'exam',
        status: 'confirmed',
        expenses: this.form.expenses
          .map(expense => ({
            code: expense.code,
            name: expense.name,
            quantity: Number(expense.quantity),
          })),
        notes: `*Encaminhamento interno por Dr(a). ${this.user.name}`,
      };

      if (this.patient.validity) {
        data.insurance.validity = this.patient.validity;
      }

      if (this.patient.record) {
        data.insurance.record = this.patient.record;
      }

      return this.$http.post('/appointments?allowInvalidExpenses=true', data)
        .then(async ({ data: result }) => {
          this.form.appointmentId = result.id;
          await this.save();
          this.$toast.show('Exame(s) agendado(s) com sucesso!');
          this.modalExamAppointment.show = false;
        })
        .catch(() => {
          this.$toast
            .show('Ocorreu um problema ao agendar. Tente novamente!', { type: 'error' });
        })
        .finally(() => {
          this.modalExamAppointment.saving = false;
        });
    },
    saveModel() {
      if (this.form.expenses.length === 0) {
        return;
      }

      this.savingModel = true;

      const names = this.form.expenses
        .map(expense => expense.name.split(' ')[0]);

      const data = {
        id: this.modelId,
        type: this.form.type,
        name: names.join(' + '),
        professionals: [
          { id: this.user.id, name: this.user.name },
        ],
        active: true,
        expenses: this.form.expenses,
      };

      const request = !data.id
        ? this.$http.post('/request-templates', data)
        : this.$http.put(`/request-templates/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          const found = this.models.find(model => model.id === result.id);
          if (!found) {
            this.models.push({
              id: result.id,
              name: result.name,
              expenses: result.expenses.length,
            });
          } else {
            found.name = result.name;
            found.expenses = result.expenses.length;
          }
          this.modelId = result.id;
          this.$toast.show('Salvo com sucesso!');
        })
        .catch(this.$toast.error)
        .then(() => {
          this.savingModel = false;
        });
    },
    save() {
      if (!this.form.insuranceId) {
        this.$toast.error('Por favor informe o convênio!');
        return false;
      }
      if (!this.form.clinicalIndication) {
        this.$toast.error('Por favor informe a indicação clínica!');
        return false;
      }

      const data = clone(this.form);

      if (!data.date || !moment(data.date, 'YYYY-MM-DD', true).isValid()) {
        delete data.date;
      }

      const request = !data.id
        ? this.$http
          .post(`/medical-records/${this.medicalRecordId}/exam-requests`, data)
        : this.$http
          .put(`/medical-records/${this.medicalRecordId}/exam-requests/${data.id}`, data);

      return request
        .then(({ data: result }) => {
          this.form.id = result.id;
        })
        .catch(this.$toast.error);
    },
    remove() {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir esta solicitação?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.$emit('remove');
              close();
            },
          },
        ],
      });
    },
    print() {
      if (!this.form.insuranceId) {
        this.$toast.error('Por favor informe o convênio!');
        return false;
      }
      if (!this.form.clinicalIndication) {
        this.$toast.error('Por favor informe a indicação clínica!');
        return false;
      }

      this.printing = true;

      return this.$file
        .print(`/medical-records/${
          this.medicalRecordId}/exam-requests/${this.form.id}/print?spsadt=${this.isSPSADT}`)
        .catch(this.$toast.error)
        .then(() => {
          this.printing = false;
        });
    },
    removeExpense(idx) {
      this.form.expenses.splice(idx, 1);
    },
    findExpense(search) {
      const params = {
        types: 'procedure',
        search,
        limit: 100,
      };

      return this.$http.get('/tuss', { params })
        .then(({ data }) => data.items);
    },
    loadMostRequested() {
      const params = {
        type: 'exam',
      };

      return this.$http.get('/professional-most-requested', { params })
        .then(({ data }) => {
          this.modalMostRequested.items = data.items.map((item) => {
            item.selected = false;
            return item;
          });
        }).catch(this.$toast.error);
    },
    loadSchedules() {
      const params = {
        limit: 0,
        offset: 0,
        active: true,
      };

      return this.$http
        .get('/schedules', { params })
        .then(({ data }) => {
          this.modalExamAppointment.schedules = data.items;
        })
        .catch(this.$toast.error);
    },
    loadScheduleProfessionals() {
      if (!this.modalExamAppointment.form.scheduleId) {
        this.modalExamAppointment.professionals = [];
        this.modalExamAppointment.form.professionalId = '';
        return null;
      }
      return this.$http
        .get(`/schedules/${this.modalExamAppointment.form.scheduleId}/professionals`)
        .then(({ data }) => {
          this.modalExamAppointment.professionals = data.items;
          if (data.items.length > 0) {
            this.modalExamAppointment.form.professionalId = data.items[0].id;
          }
        })
        .catch(this.$toast.error);
    },
    setExpense(data) {
      this.expense = null;
      const found = this.form.expenses.find(item => item.id === data.id);
      if (found) {
        const result = Number(found.quantity);
        found.quantity = result + 1;
      } else {
        this.form.expenses.push({
          key: randomHash(),
          id: data.id,
          code: data.code,
          name: data.name,
          quantity: 1,
        });
      }
    },
    addLastRequests(item) {
      this.form.expenses.push(item);
    },
    clearModalExamAppointment() {
      this.$v.modalExamAppointment.form.$reset();
      this.modalExamAppointment.form.date = moment().format('YYYY-MM-DD');
      this.modalExamAppointment.form.scheduleId = '';
      this.modalExamAppointment.form.professionalId = '';
    },
    addDate() {
      this.modalExamAppointment.form.date = moment(this.modalExamAppointment.form.date)
        .add(1, 'days')
        .format('YYYY-MM-DD');
    },
    requesterEdit() {
      if (this.professionals.length === 0) {
        this.loadProfessionals();
      }
      this.form.professional.id = this.user.id;
      this.form.professional.name = this.user.name;
      this.requesterProfessionalEdit = true;
    },
    requesterSave() {
      const found = this.professionals.find(({ id }) => id === this.form.professional.id);
      this.form.professional.name = found.name;
      this.requesterProfessionalEdit = false;
    },
    requesterRemove() {
      this.form.professional.id = '';
      this.form.professional.name = 'Não informar';
      this.requesterProfessionalEdit = false;
    },
    removeDate() {
      this.modalExamAppointment.form.date = moment(this.modalExamAppointment.form.date)
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
    },
  },
};
</script>

<style lang="scss">
  @import "../../../../../assets/scss/variables";

  $bg-color: lighten($gray-color, 24%);

  .card-exam-page {
    background-color: $bg-color;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing-lg;
    .card-exam-header {
      background-color: lighten($gray-color, 15%);
      padding: $layout-spacing;
    }
    .card-exam-body {
      padding: $layout-spacing;
      .table {
        margin-bottom: $layout-spacing-lg;
        tbody tr {
          background-color: $gray-color-light;
        }
        td {
          border-bottom-color: $bg-color;
        }
      }
    }
    .card-exam-footer {
      text-align: center;
      padding: $layout-spacing-lg;
      button {
        min-width: 7rem;
      }
    }
  }
  #modal-exam-schedule {
    .date-item {
      align-items: center;
      background-color: $gray-color-ultra-light;
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      display: flex;
      justify-content: space-between;
      padding: $layout-spacing-sm $layout-spacing;
      text-align: center;
    }
  }
</style>
