<template>
  <div class="medical-records" ref="medicalRecordPage">
    <portal to="page-name">Prontuário</portal>
    <div class="loading loading-lg" v-if="loadingPatient || loading" />
    <template v-else>
      <record-header
        :patient="patient"
        :loading="loadingPatient"
        :appointment="appointment"
      >
        <template v-if="hasPendingRecords">
          <span class="medical-record-timer">{{ timer }}</span>
          <button class="btn btn-lg btn-gray"
                  :class="{loading: canceling}" :disabled="canceling"
                  @click="cancel">Cancelar
          </button>
          <button class="btn btn-lg btn-error"
                  :class="{loading: isLoading}" :disabled="isLoading"
                  @click="finishing">Finalizar
          </button>
        </template>
        <template v-else>
          <button class="btn btn-lg btn-primary"
                  :class="{loading: isLoading}" :disabled="isLoading"
                  @click="start">Iniciar atendimento
          </button>
          <button
            v-if="hasPCSModule"
            class="btn btn-icon btn-gray-outline btn-lg tooltip-bottom"
            :class="{ loading: pcsCalling, tooltip: !pcsCalling }"
            :disabled="!patient.id"
            data-tooltip="Chamar paciente"
            @click="callPatient"
          >
            <fa-icon :icon="['fal', 'bullhorn']"  />
          </button>
          <button class="btn btn-icon btn-gray-outline btn-lg tooltip tooltip-bottom"
                  :disabled="records.length === 0"
                  data-tooltip="Imprimir"
                  @click="showPrint">
            <fa-icon :icon="['fal', 'print']" />
          </button>
          <button class="btn btn-icon btn-gray-outline btn-lg tooltip tooltip-bottom"
                  data-tooltip="Voltar"
                  @click="$router.back()">
            <fa-icon :icon="['fal', 'arrow-left']" />
          </button>
        </template>
      </record-header>

      <div class="page-container">
        <template v-if="hasPendingRecords">
          <div class="card card-page medical-record-form">
            <st-tabs id="medical-record-tabs" ref="medical-record-tabs"
                     @changed="changeTabs">
              <st-tab id="tab-anamnesis" name="Anamnese / evolução">
                <anamnesis-form
                  :patient="patient"
                  :data="form"
                  :pending="pending"
                  :expenses="appointment.expenses"
                  :ophthalmology="ophthalmologyHistory"
                  v-if="form.id"/>
              </st-tab>
              <st-tab
                id="tab-request" name="Solicitação"
                v-if="!patient.hospitalization || !patient.hospitalization.id"
              >
                <request-form :patient="patient"
                              :appointment="appointment"
                              :medicalRecordId="form.id" v-if="form.id"
                              @scroll-top="scrollTop"
                />
              </st-tab>
              <st-tab id="tab-prescriptions" name="Prescrição"
                      v-if="patient.hospitalization && patient.hospitalization.id">
                <prescription-form
                  v-if="form.id"
                  :medicalRecordId="form.id"
                  :patient="patient"
                  :patientId="patient.id"
                  @scroll-top="scrollTop"
                />
              </st-tab>
              <st-tab id="tab-recipes" name="Receituário">
                <recipe-form
                  v-if="form.id"
                  :medicalRecordId="form.id"
                  :patient="patient"
                  :patientId="patient.id"
                  @scroll-top="scrollTop"
                />
              </st-tab>
              <st-tab id="tab-documents" name="Impressos">
                <document-form
                  :medicalRecordId="form.id"
                  :patient="patient"
                  v-if="form.id"
                  @scroll-top="scrollTop"
                />
              </st-tab>
              <st-tab id="tab-attachment" name="Anexos">
                <st-entity-file-manager
                  v-if="form.id"
                  :entity-id="patient.id"
                  :origin-id="form.id"
                  origin-type="medical_record"
                  :list-all="listAllFiles"
                  :key="form.id"
                  @loadingFiles="loadingFiles"
                />
                <button class="btn btn-neutral mt-2"
                        :class="{ loading: loadingAllFiles }"
                        :disabled="loadingAllFiles"
                        @click="listAllFiles = !listAllFiles">
                  <span v-if="listAllFiles">Ocultar outros anexos</span>
                  <span v-else>Listar outros anexos</span>
                </button>
              </st-tab>
            </st-tabs>
          </div>
        </template>
        <div class="hospitalization-info-group"
             v-if="patient.hospitalization && patient.hospitalization.id">
          <fa-icon :icon="['fal', 'exclamation-triangle']" />
          <span class="mr-2">
                Paciente internado desde o dia {{ patient.hospitalization.date | date }}
                às {{ patient.hospitalization.date | date('time') }}
              </span>|
          <label class="form-switch ml-2">
            <input type="checkbox" v-model="filterHospitalizationRecords">
            <i class="form-icon"></i>Exibir apenas os atendimentos do internamento atual
          </label>
        </div>
        <div class="filter-group">
          <div class="columns">
            <div class="column col-8">
              <label class="form-switch">
                <input type="checkbox" v-model="filter.justMyServices" />
                <i class="form-icon"/>Filtrar apenas meus atendimentos
              </label>
            </div>
            <div class="column col-4">
              <div class="input-group">
                <input type="text"
                       class="form-input"
                       v-model="filter.search"
                       placeholder="Pesquisar queixa principal"
                >
                <button class="btn btn-neutral btn-action input-group-btn btn-icon" tabindex="-1">
                  <fa-icon :icon="['fal', 'search']"/>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="empty mt-2" v-if="!hasPendingRecords && filteredRecords.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Paciente sem histórico</p>
          <p class="empty-subtitle">
            Nenhuma informação registrada deste paciente.
            Clique no botão abaixo para iniciar.
          </p>
          <button class="btn btn-primary"
                  @click="start">Iniciar atendimento
          </button>
        </div>
        <template v-if="filteredRecords.length > 0 && showHistory">
          <div class="h5 text-center pending-records"
               v-if="hasPendingRecords">
            Atendimentos anteriores
          </div>
          <template v-if="!detail.show">
            <item-form
              v-for="(record, i) in filteredRecords" :key="i"
              :item="record"
              @openDetail="openDetail"
            />
          </template>
          <detail
            :id="detail.id"
            @close="closeDetail"
            v-else
          />
        </template>
      </div>
    </template>

    <dx-modal title="Finalização" v-if="finish.show" :value="finish.show"
              @input="closeModalFinished"
              id="modal-finish" size="sm">
      <label class="form-switch">
        <input type="checkbox" v-model="form.private"/>
        <i class="form-icon"/> Definir atendimento como sigiloso
      </label>
      <label class="form-switch">
        <input type="checkbox" v-model="reminder"/>
        <i class="form-icon"/> Lembre-me deste atendimento
      </label>
      <template v-if="reminder">
        <div class="divider" />
        <div class="columns">
          <div class="column col-12 form-group"
               :class="{'has-error': $v.form.reminder.date.$error}">
            <label class="form-label">Data</label>
            <div class="input-group">
              <dx-dropdown
                class="input-group-addon"
                :items="period"
                label="text"
                @select="setPeriod"
                direction="left"
              ><fa-icon :icon="['fal', 'chevron-down']" /></dx-dropdown>
              <dx-input-date
                id="reminder-date" class="form-input text-center"
                v-model="form.reminder.date" placeholder="00/00/0000"
                @blur="$v.form.reminder.date.$touch()"
              />
            </div>
            <template v-if="$v.form.reminder.date.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.reminder.date.required">Campo obrigatório
              </div>
              <div class="form-input-hint"
                   v-else-if="!$v.form.reminder.date.date">Data inválida
              </div>
              <div class="form-input-hint"
                   v-else-if="!$v.form.reminder.date.minDate">
                Data inválida
              </div>
            </template>
          </div>
        </div>
      </template>
      <template slot="footer">
        <button
          class="btn btn-error btn-block"
          :class="{ loading: this.saving }"
          :disabled="this.saving"
          @click="finished"
          v-focus
        >Finalizar</button>
      </template>
    </dx-modal>
    <dx-modal title="Imprimir" v-model="print.show" v-if="print.show"
              id="modal-print" size="sm">
      <label class="form-checkbox print-check">
        <input type="checkbox" v-model="print.justMe"/>Apenas meus atendimentos
        <i class="form-icon"/>
      </label>
      <div class="medical-records-print-item"
           v-for="(print, p) in filteredPrintRecords" :key="p">
        <span>{{ print.date | date('DD/MM/YYYY HH:mm') }}</span><br>
        <span>{{ print.professionalName }}</span>
        <label class="form-checkbox print-check">
          <input type="checkbox" v-model="print.selected"/>
          <i class="form-icon"/>
        </label>
      </div>
      <template slot="footer">
        <button class="btn btn-primary mr-1"
                :disabled="print.printing"
                :class="{loading: print.printing}"
                @click="printMultiple">Imprimir
        </button>
        <button class="btn" @click="print.show = false">Sair</button>
      </template>
    </dx-modal>
    <patient-alert
      v-if="patientAlertModal.show"
      :patient-id="patient.id"
      :patient-name="patient.name"
      :external-data="patientAlertModal.data"
      :show="patientAlertModal.show"
      @close="patientAlertModal.show = false"
    />
    <config-modal v-if="hasPCSModule"></config-modal>
  </div>
</template>

<script>
import moment from 'moment';
import fuzzy from 'fuzzy';
import formMixin from 'src/mixins/form';
import { mapState, mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { mergeFrom } from '@/helpers/object';
import { createAndDispatch } from '@/helpers/link';
import { pcsMainUrl } from '@/config';
import RecordHeader from './record/Header.vue';
import AnamnesisForm from './anamnesis/Form.vue';
import DocumentForm from './document/Form.vue';
import RecipeForm from './recipe/Form.vue';
import PrescriptionForm from './prescription/Form.vue';
import RequestForm from './request/Form.vue';
import ItemForm from './ListItem.vue';
import { date, minDate } from '../../../data/validators';
import Detail from './DetailItem.vue';
import PatientAlert from '../../patient/modals/Alert.vue';
import ConfigModal from '../../attendance/components/modals/Config.vue';

export default {
  mixins: [formMixin],
  components: {
    ConfigModal,
    RecordHeader,
    AnamnesisForm,
    DocumentForm,
    RecipeForm,
    PrescriptionForm,
    RequestForm,
    ItemForm,
    Detail,
    PatientAlert,
  },
  data() {
    return {
      timer: '00:00:00',
      debounceTimeout: null,
      timerInterval: null,
      unwatch: null,
      saving: false,
      loading: false,
      loadingPatient: true,
      pcsCalling: false,
      printing: false,
      pending: false,
      canceling: false,
      showHistory: true,
      reminder: false,
      listAllFiles: false,
      loadingAllFiles: false,
      idx: null,
      filter: {
        justMyServices: false,
        search: '',
      },
      period: [
        { value: 7, text: '7 dias' },
        { value: 15, text: '15 dias' },
        { value: 30, text: '30 dias' },
        { value: 60, text: '60 dias' },
        { value: 90, text: '90 dias' },
        { value: 120, text: '120 dias' },
        { value: 180, text: '180 dias' },
        { value: 365, text: '365 dias' },
      ],
      filterHospitalizationRecords: false,
      detail: {
        show: false,
        id: '',
      },
      image: this.imageFormBlank(),
      appointment: {
        expenses: [],
        specialtyCode: 0,
        insurancePlanId: '',
        insuranceName: '',
      },
      patient: {
        id: '',
        name: '',
        socialName: '',
        gender: '',
        identity: {
          type: '',
          value: '',
        },
        birthDate: '',
        phone: '',
        cellphone: '',
        addresses: [],
        imageUrl: '',
        insuranceName: '',
        insuranceId: '',
        insurancePlanId: '',
        motherName: '',
        allergy: '',
        record: '',
        validity: '',
        hospitalization: {
          id: '',
          date: '',
        },
      },
      records: [],
      finish: {
        show: false,
      },
      print: {
        show: false,
        justMe: false,
        printing: false,
        items: [],
      },
      patientAlertModal: {
        show: false,
        data: null,
      },
      ophthalmologyHistory: {
        autoRefractionItems: [],
        keratometryItems: [],
        lensometryItems: [],
        pioItems: [],
        avItems: [],
        subjectiveItems: [],
        objectiveItems: [],
      },
      form: this.formBlank(),
    };
  },
  async mounted() {
    const patientId = this.$route.params.id;
    this.loadAppointment();
    await this.findPatient(patientId);
    await this.loadRecords(patientId);

    const medicalRecord = this.records
      .find(item => item.status === 'pending'
        && item.professional.id === this.user.id);

    if (medicalRecord) {
      await this.load(medicalRecord.id)
        .then(() => {
          this.setWatchers();
          this.startTimer();
        });
    } else {
      this.setWatchers();
    }
    this.loadOphthalmologyHistory();
  },
  beforeDestroy() {
    this.endTimer();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    ...mapGetters({
      branchSettings: 'branchSettings',
      hasModule: 'hasModule',
    }),
    isLoading() {
      return this.saving || this.pending;
    },
    bgImage() {
      if (!this.patient.gender) {
        return null;
      }

      let backgroundImage = `url('//static.stenci.pro/images/no-image-${
        this.patient.gender}.png')`;

      if (this.image.preview) {
        backgroundImage = `url('${this.image.preview}')`;
      } else if (this.patient.imageUrl) {
        backgroundImage = `url('${this.patient.imageUrl}?w=200&h=200')`;
      }

      return { backgroundImage };
    },
    showImageOptions() {
      if (this.image.showOptions) {
        return true;
      }
      return this.bgImage === null;
    },
    hasPendingRecords() {
      return this.records
        .some(item => item.status === 'pending'
          && item.professional.id === this.user.id);
    },
    filteredRecords() {
      let { records } = this;

      if (this.filter.justMyServices) {
        records = records
          .filter(item => item.professional.id === this.user.id);
      }

      if (this.filter.search.trim()) {
        const search = this.removeAccentuation(this.filter.search.trim());
        const results = fuzzy.filter(search, records, {
          extract: (el) => {
            if (el.anamnesis
              && Array.isArray(el.anamnesis.inputs)
              && el.anamnesis.inputs.length > 0
              && el.anamnesis.inputs[0].value) {
              return this.removeAccentuation(el.anamnesis.inputs[0].value);
            }
            return '';
          },
        });
        records = results.map(({ original }) => original);
      }

      if (this.patient.hospitalization
        && this.patient.hospitalization.id
        && this.filterHospitalizationRecords) {
        return records
          .filter(item => item.status !== 'pending'
            && item.hospitalizationId === this.patient.hospitalization.id);
      }

      return records.filter(item => item.status !== 'pending');
    },
    filteredPrintRecords() {
      let records = this.print.items;
      if (this.print.justMe) {
        records = records
          .filter(item => item.professionalId === this.user.id);
      }
      return records;
    },
    hasPCSModule() {
      return this.hasModule('pcs');
    },
  },
  validations() {
    const rules = {
      form: {
        reminder: {
          date: {},
        },
      },
    };

    if (this.reminder) {
      rules.form.reminder.date = {
        required,
        date,
        minDate: minDate(),
      };
    }

    return rules;
  },
  methods: {
    ...mapActions({
      loadAttendanceConfig: 'loadAttendanceConfig',
    }),
    loadOphthalmologyHistory() {
      this.filteredRecords
        .filter(({ anamnesis }) => anamnesis.templateRenderer === 'ophthalmology')
        .forEach((record) => {
          this.ophthalmologyHistory.autoRefractionItems.push({
            date: record.startDate,
            spD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c95987e').value,
            spE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c95987f').value,
            cyD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959880').value,
            cyE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959881').value,
            axD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959882').value,
            axE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959883').value,
          });
          this.ophthalmologyHistory.keratometryItems.push({
            date: record.startDate,
            k1D: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959884').value,
            k1E: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959885').value,
            k2D: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959886').value,
            k2E: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959887').value,
            axD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959888').value,
            axE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959889').value,
          });
          this.ophthalmologyHistory.lensometryItems.push({
            date: record.startDate,
            spD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c95988a').value,
            spE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c95988b').value,
            cyD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c95988c').value,
            cyE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c95988d').value,
            axD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c95988e').value,
            axE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c95988f').value,
            add: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8d95988a').value,
            qtd: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7d8c95988b').value,
          });
          this.ophthalmologyHistory.pioItems.push({
            date: record.startDate,
            apD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959890').value,
            apE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959891').value,
            atD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959892').value,
            atE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959893').value,
            piD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959894').value,
            piE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959895').value,
            ptD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959896').value,
            ptE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959897').value,
          });
          this.ophthalmologyHistory.avItems.push({
            date: record.startDate,
            scD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959898').value,
            scE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c959899').value,
            ccD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c95989a').value,
            ccE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c95989b').value,
          });
          this.ophthalmologyHistory.subjectiveItems.push({
            date: record.startDate,
            spD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c95989c').value,
            spE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c95989d').value,
            cyD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c95989e').value,
            cyE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c95989f').value,
            axD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c9598a0').value,
            axE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c9598a1').value,
            avD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c9598a8').value,
            avE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c9598a9').value,
            add: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c9598aa').value,
            adj: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8a9598aa').value,
          });
          this.ophthalmologyHistory.objectiveItems.push({
            date: record.startDate,
            spD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c9598a2').value,
            spE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c9598a3').value,
            cyD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c9598a4').value,
            cyE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c9598a5').value,
            axD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c9598a6').value,
            axE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c9598a7').value,
            avD: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8d9598a8').value,
            avE: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8d9598a9').value,
            add: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8c9598ab').value,
            adj: record.anamnesis.inputs.find(({ id }) => id === '60ede9a29f3f7f7c8d9598ab').value,
          });
        });
    },
    loadingFiles(value) {
      this.loadingAllFiles = value;
    },
    loadAppointment() {
      const { appointmentId } = this.$route.query;

      if (appointmentId) {
        this.$http.get(`/appointments/${appointmentId}`)
          .then(({ data }) => {
            this.appointment.specialtyCode = 0;
            if (data.professional && data.professional.specialty) {
              this.appointment.specialtyCode = data.professional.specialty.code;
            }
            this.appointment.insurancePlanId = data.insurance.plan.id;
            this.appointment.insuranceName = this.getCustomName(data.insurance);
            this.appointment.expenses = data.expenses;
          })
          .catch(this.showError);
      }
    },
    setWatchers() {
      this.unwatch = this.$watch('form', {
        handler() {
          this.pending = true;
          clearTimeout(this.debounceTimeout);
          this.debounceTimeout = setTimeout(() => {
            if (this.form.id) {
              this.save(false).catch(this.showError);
            }
          }, 1000);
        },
        deep: true,
      });
    },
    scrollTop() {
      this.$refs.medicalRecordPage.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    },
    openDetail(id) {
      this.detail.id = id;
      this.detail.show = true;
    },
    closeDetail() {
      this.detail.id = '';
      this.detail.show = false;
    },
    async callPatient() {
      this.pcsCalling = true;
      try {
        const config = await this.loadAttendanceConfig();
        if (config) {
          const data = {
            place: config.placeId,
            patient: {
              name: this.patient.name,
            },
            room: {
              name: config.roomName,
            },
          };
          await this.$http.post(`${pcsMainUrl}/ticket/call`, data);
        }
      } catch (e) {
        this.showError(e);
      }
      this.pcsCalling = false;
    },
    load(id) {
      this.loading = true;
      return this.$http
        .get(`/medical-records/${id}`)
        .then(({ data }) => {
          const mergeData = data || this.formBlank();
          mergeFrom(this.form, mergeData);
        })
        .catch(this.showError)
        .finally(() => {
          this.loading = false;
        });
    },
    loadRecords(patientId) {
      this.loading = true;
      return this.$http
        .get(`/patients/${patientId}/medical-records?limit=0`)
        .then(({ data }) => {
          if (data.items && data.items.length > 0) {
            data.items = data.items.filter(item => !item.private || (
              item.private && item.professional
              && item.professional.id === this.user.id));
          }
          this.records = data.items;
        })
        .catch(this.showError)
        .finally(() => {
          this.loading = false;
        });
    },
    loadAlert() {
      const params = {
        displayTo: 'medical_record',
      };

      this.$http.get(`/patients/${this.patient.id}/alerts`, { params })
        .then(({ data: result }) => {
          if (result && result.items && result.items.length > 0) {
            this.patientAlertModal.data = result;
            this.patientAlertModal.show = true;
          }
        })
        .catch(this.showError);
    },
    async validate() {
      if (!this.patient.insuranceId) {
        throw new Error((
          'Paciente sem convênio definido. Verifique o cadastro do paciente'
        ));
      }
      if (!await this.allowServiceWithoutAccount()) {
        throw new Error((
          'Não será possível iniciar o atendimento. Liberação não realizada na recepção.'
        ));
      }
    },
    async start() {
      try {
        this.saving = true;
        await this.validate();

        this.form = this.formBlank();
        this.form.startDate = moment().format('YYYY-MM-DDTHH:mm:ss');
        this.form.start = true;
        this.reminder = false;

        await this.save(false);
        this.startTimer();
        this.scrollTop();
      } catch (e) {
        this.showError(e);
      } finally {
        this.saving = false;
      }
    },
    cancel() {
      this.$dialog.show('', {
        html:
          `<div class="text-center">
          <h5 class="text-center">Atenção!</h5>
          <div>Deseja realmente cancelar este atendimento?</div>
          </div>`,
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.canceling = true;
              this.delete(this.form.id)
                .then(() => {
                  this.endTimer();
                  const record = this.records.find(item => item.id === this.form.id);
                  const index = this.records.indexOf(record);
                  this.records.splice(index, 1);
                })
                .catch(this.showError)
                .then(() => {
                  close();
                  this.closeDetail();
                  this.canceling = false;
                  this.showHistory = true;
                  this.$router.back();
                });
            },
          },
        ],
      });
    },
    finishing() {
      if (this.unwatch) {
        this.unwatch();
      }
      this.reminder = false;
      this.form.reminder.date = '';
      this.$v.form.reminder.$reset();
      this.form.private = this.getPrivateStatus();
      this.finish.show = true;
      this.showHistory = true;
    },
    finished() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.endTimer();
      this.saving = true;
      this.save(true)
        .then(() => {
          this.closeDetail();
          this.showHistory = true;
          this.finish.show = false;
          this.conectadocOutcome();
          this.$router.back();
        })
        .catch(this.showError);
    },
    async save(finished) {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return false;
      }

      this.saving = true;

      const data = this.clone(this.form);
      if (!data.appointmentId && this.$route.query.appointmentId) {
        data.appointmentId = this.$route.query.appointmentId;
      }

      if (this.patient.hospitalization
        && this.patient.hospitalization.id) {
        data.hospitalizationId = this.patient.hospitalization.id;
      }

      data.status = finished ? 'finished' : 'pending';
      data.endDate = finished ? moment()
        .format('YYYY-MM-DDTHH:mm') : '';
      data.professionalId = this.user.id;
      data.patientId = this.patient.id;
      data.insuranceId = this.patient.insuranceId || null;

      data.anamnesis.inputs = data.anamnesis.inputs.map(item => ({
        _id: item.id,
        value: item.model.value,
        observation: item.model.observation,
      }));

      const request = !this.form.id
        ? this.$http.post('/medical-records', data)
        : this.$http.put(`/medical-records/${this.form.id}`, data);

      return request
        .then(({ data: medicalRecordData }) => {
          this.form.id = medicalRecordData.id;
          const found = this.records.find(item => item.id === medicalRecordData.id);
          if (found) {
            mergeFrom(found, medicalRecordData);
          } else {
            this.records.unshift(medicalRecordData);
          }
          if (finished) {
            this.$toast.show('Atendimento salvo com sucesso');
          }
        })
        .catch((e) => {
          e = e || new Error();
          if (e.response?.data) {
            e.message = e.response.data.message || e.message;
            delete e.response;
          }

          e.message = [
            'Ocorreu um erro ao salvar este registro. Tente novamente!',
            `[${e.message}]`,
          ].join('\n');

          throw e;
        })
        .finally(() => {
          this.saving = false;
          this.pending = false;
        });
    },
    delete(id) {
      return this.$http
        .delete(`/medical-records/${id}`)
        .then(({ data }) => data)
        .catch(this.showError);
    },
    closeModalFinished() {
      this.finish.show = false;
      this.setWatchers();
    },
    startTimer() {
      this.setTimer();
      this.timerInterval = setInterval(() => {
        this.setTimer();
      }, 800);
    },
    endTimer() {
      clearInterval(this.timerInterval);
      this.timer = '00:00:00';
    },
    setTimer() {
      this.timer = this.$timeDiff(this.form.startDate);
    },
    findPatient(patientId) {
      this.loadingPatient = true;
      return this.$http.get(`/patients/${patientId}`)
        .then(({ data }) => {
          this.patient = {
            id: data.id,
            name: data.name,
            socialName: data.socialName,
            birthDate: data.birthDate,
            gender: data.gender,
            phone: data.phone,
            cellphone: data.cellphone,
            identity: data.identity,
            imageUrl: data.imageUrl,
            addresses: data.addresses,
            motherName: data.patient && data.patient.motherName
              ? data.patient.motherName : '',
            insuranceName: data.patient && data.patient.insurance
              ? this.getCustomName(data.patient.insurance) : '',
            insuranceId: data.patient && data.patient.insurance
              ? data.patient.insurance.id : '',
            insurancePlanId: data.patient
            && data.patient.insurance
            && data.patient.insurance.plan
              ? data.patient.insurance.plan.id : '',
            allergy: data.patient && data.patient.allergy
              ? data.patient.allergy : false,
            record: data.patient && data.patient.insurance
              ? data.patient.insurance.record : '',
            validity: data.patient && data.patient.insurance
              ? data.patient.insurance.validity : '',
            hospitalization: data.patient && data.patient.hospitalization
              ? data.patient.hospitalization : null,
          };
          this.loadAlert();
        })
        .finally(() => {
          this.loadingPatient = false;
        });
    },
    getCustomName(insurance) {
      if (insurance.plan && insurance.name !== insurance.plan.name) {
        return `${insurance.name} (${insurance.plan.name})`;
      }
      return insurance.name;
    },
    changeTabs({ tab }) {
      this.showHistory = tab.hash === 'tab-anamnesis';
    },
    imageFormBlank() {
      return {
        file: null,
        preview: null,
        showOptions: false,
        showWebCam: false,
      };
    },
    showPrint() {
      this.print.items = this.records
        .map(item => ({
          id: item.id,
          date: item.startDate,
          professionalId: item.professional.id,
          professionalName: item.professional.name,
          selected: true,
        }));
      this.print.show = true;
    },
    printMultiple() {
      const ids = this.filteredPrintRecords
        .filter(({ selected }) => selected)
        .map(({ id }) => id);

      if (!ids || ids.length === 0) {
        return null;
      }

      this.print.printing = true;

      return this.$file
        .print('/medical-records/printMultiple', { ids: ids.join(',') })
        .catch(this.showError)
        .then(() => {
          this.print.printing = false;
        });
    },
    getPrivateStatus() {
      // 251510 - Psicólogo; 225133 - Psiquiatra
      // if (this.appointment.specialtyCode.toString() === '251510'
      //   || this.appointment.specialtyCode.toString() === '225133') {
      //   return true;
      // }
      return false;
    },
    conectadocOutcome() {
      if (!this.branchSettings['conectadoc.outcome']) return;

      const params = {
        facilityId: this.user.branch.identityId,
        facilityName: this.user.branch.name,
        patientIdentifier: this.patient.identity ? this.patient.identity.value : '',
        patientName: this.patient.name,
        doctorIdentifier: this.user.identity ? this.user.identity.value : '',
        doctorName: this.user.name,
        attentionId: this.form.id,
      };
      const token = btoa((
        Object.keys(params)
          .map(key => `${key}=${params[key]}`)
          .join('&')
      ));

      createAndDispatch(
        `https://conectadoc.com.br/DesfechoConsulta.aspx?TokenIntegra=${token}`,
        { target: '_blank' },
      );
    },
    allowServiceWithoutAccount() {
      if (
        this.user.branch.settings
        && this.user.branch.settings['clinical.blockServiceWithoutAccount']
      ) {
        const appointmentId = this.$route.query && this.$route.query.appointmentId
          ? this.$route.query.appointmentId
          : null;

        if (appointmentId) {
          return this.$http.get(`/appointments/${appointmentId}`)
            .then(({ data }) => {
              if (data && Array.isArray(data.expenses) && data.expenses.length > 0) {
                return data.expenses && data.expenses.every(({ accountId }) => accountId);
              }
              return false;
            });
        }
        return false;
      }
      return true;
    },
    setPeriod({ value }) {
      this.form.reminder.date = moment().add(value, 'days').format('YYYY-MM-DD');
    },
    formBlank() {
      return {
        id: null,
        start: false,
        status: '',
        appointmentId: '',
        startDate: '',
        endDate: '',
        private: false,
        screeningId: '',
        professionalId: '',
        patientId: '',
        insurancePlanId: '',
        anthropometry: {
          cephalicPerimeter: '',
          abdominalCircumference: '',
          height: '',
          weight: '',
        },
        vitalSigns: {
          bloodPressure: '',
          respiratoryFrequency: '',
          heartRate: '',
          temperature: '',
          saturation: '',
        },
        reminder: {
          date: '',
          notes: '',
        },
        cids: [],
        anamnesis: {
          templateId: '',
          templateRenderer: null,
          inputs: [],
        },
      };
    },
    showError(e, options = {}) {
      options = { timeout: 15000, ...options };
      this.$toast.error(e, options);
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.medical-records {
  $page-header-height: 4.2rem;

  .medical-record-header {
    background-color: $light-color;
    border-bottom: 1px solid $border-color;
    height: $page-header-height;
    position: sticky;
    top: 0;
    z-index: $zindex-0 + 1;
  }

  .pending-records {
    margin: $layout-spacing-xl;
  }

  .medical-record-form {
    padding: $layout-spacing-lg;
    margin-bottom: $layout-spacing;
    .tab {
      margin-bottom: $layout-spacing;

      a {
        padding-top: 0;
      }
    }
  }

  .medical-record-timer {
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
  }

  .filter-group {
    background-color: $gray-color-light;
    border: $border-width solid $gray-color;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing-lg;
    padding: $layout-spacing;
    input {
      &:focus {
        z-index: auto;
      }
    }
  }

  .hospitalization-info-group {
    align-items: center;
    background-color: lighten($info-color, 32%);
    border: $border-width solid $info-color;
    border-radius: $border-radius;
    flex-direction: row;
    display: flex;
    margin-bottom: $layout-spacing-lg;
    padding: $layout-spacing-sm $layout-spacing;
    span {
      font-weight: 600;
    }
    svg {
      color: $info-color;
      font-size: .7rem;
      width: 1.2rem;
    }
  }

  p {
    margin: 0;
  }
}

.medical-records-print-item {
  background-color: lighten($gray-color-light, 5%);
  border: $border-width solid $border-color;
  border-radius: $border-radius;
  position: relative;
  margin: $layout-spacing-sm;
  padding: $layout-spacing-sm;

  .print-check {
    position: absolute;
    top: .4rem;
    right: 0;
  }
}
</style>
